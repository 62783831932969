<template>
  <div class="index">
    <div class="wrap card_wrap">
      <div class="box boxs">
        <div class="dd_title">
          <div class="dd_word">最新比赛</div>
        </div>
        <ul class="dd_cpt_contain clip_card">
          <li
            class="cpt_type0"
            v-for="(item, index) in MatchListAll"
            :key="index"
            :name="(index + 1) % 4 == 0 ? '' : 'cptmarginRight'"
          >
            <div class="dd_cpt_card_wrap">
              <div class="dd_cpt_logo">
                <img :src="item.challenge_image_path" alt="" />
                <div
                  class="status_icon_g"
                  :style="
                    item.challenge_status == 0 ? 'color: white;' : 'color:red;'
                  "
                >
                <!-- item.challenge_status == 0? "进行中": item.challenge_status == 1? "已结束": "已暂停" -->
                  {{
                      CalculateGameStatus(item.registration_time,item.start_time,item.en_time,item.challenge_status)
                  }}
                </div>
              </div>
              <div class="cpt_card_wrap">
                <div class="dd_cpt_intro1">
                  <p class="dd_cpe_name">
                    {{ item.challenge_title }}
                  </p>
                </div>
                <div class="dd_cpt_intro2">
                  <div class="dd_cpt_time">报名开始时间：{{ item.registration_time }}</div>
                  <div class="dd_cpt_time">比赛开始时间：{{ item.start_time }}</div>
                  <div class="dd_cpt_time">比赛结束时间：{{ item.en_time }}</div>
                </div>
              </div>
            </div>
            <!-- 遮罩层 -->
            <div class="shade_wrap" v-if="item.challenge_status == 0">
              <div class="dd_hover_shade">
                <p>
                  {{((new Date(getNowDate()) > new Date(item.registration_time)) && (new Date(item.start_time)) > new Date(getNowDate())) ? `比赛将于${item.start_time}开始`:`比赛将于${item.en_time}结束`}}
                  </p>
                <button @click="RaceJump(item)">
                  {{
                    ((new Date(getNowDate()) > new Date(item.registration_time)) && (new Date(item.start_time)) > new Date(getNowDate())) ? '立即报名':'立即参与'
                  }}
                </button>
              </div>
            </div>
            <div class="shade_wrap" v-if="item.challenge_status != 0">
              <div class="dd_hover_shade">
                <p>
                  {{
                    item.challenge_status == -1 ? "比赛已暂停" : "比赛已结束"
                  }}
                </p>
                <button @click="RaceJump(item)">参看比赛</button>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div
        class="dd_more_cpt_btn"
        v-if="this.MatchListAll.length > 4"
        @click="JumpAll"
      >
        查看更多比赛
      </div>
    </div>
  </div>
</template>

<script>
import { MatchList } from "../api/CompetitionList.js";
export default {
  name: "index",
  data() {
    return {
      imgArr: [],
      Params: {
        keywords: "",
        pageNum: 1,
        pageSize: 4,
      },
      MatchListAll: [],
    };
  },
  mounted() {
    console.log(new Date(this.getNowDate()))
    this.getData();
  },
  created() {
  },
  methods: {
    // 计算比赛状态
    CalculateGameStatus(registration_time,start_time,en_time,challenge_status){
      if(challenge_status == '-1'){
        return '已暂停'
      }else if(challenge_status == '1'){
        return '已结束'
      }else if(challenge_status == '0'){
        if(new Date(this.getNowDate()) > new Date(registration_time) &&  new Date(this.getNowDate()) < new Date(start_time)){
          return '报名中'
        }else if(new Date(this.getNowDate()) > new Date(start_time) &&  new Date(this.getNowDate()) < new Date(en_time)){
          return '进行中'
        }else{
          return '进行中'
        }
      }
    },
    // 格式化日对象
    getNowDate() {
      var date = new Date();
      var sign2 = ":";
      var year = date.getFullYear() // 年
      var month = date.getMonth() + 1; // 月
      var day = date.getDate(); // 日
      var hour = date.getHours(); // 时
      var minutes = date.getMinutes(); // 分
      var seconds = date.getSeconds() //秒
      // 给一位数的数据前面加 “0”
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (day >= 0 && day <= 9) {
        day = "0" + day;
      }
      if (hour >= 0 && hour <= 9) {
        hour = "0" + hour;
      }
      if (minutes >= 0 && minutes <= 9) {
        minutes = "0" + minutes;
      }
      if (seconds >= 0 && seconds <= 9) {
        seconds = "0" + seconds;
      }
      return year + "-" + month + "-" + day + " " + hour + sign2 + minutes + sign2 + seconds;
    },
    getData() {
      MatchList(this.Params).then((res) => {
        this.MatchListAll = res.data.list;
      });
    },
    RaceJump(item) {
      // if (item.challenge_status == 1) {
      // 	this.$message.error("当前比赛已结算，请下次开放时参加！");
      // 	return;
      // }
      this.$router.push({
        path: "/SingleItem",
        query: {
          id: item.challenge_id,
          matchstate: item.challenge_status,
        },
      });
    },
    JumpAll() {
      this.$router.push("/CompetitionList");
    },
  },
};
</script>

<style lang='scss' scoped>
@media (max-width: 1400px) {
  .boxs {
    width: 1920px;
  }
}
@media (min-width: 992px) {
  .boxs {
    width: 1080px;
  }
}
.index {
  background-color: rgb(245, 250, 252);
//   margin-bottom: 110px;
  height: auto;
  .wrap {
    min-width: 1080px;
    width: 100%;
    .box {
      // width: 1080px;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      .dd_title {
        // padding-top: 60px;
        // margin-top: 20px;
        // padding-bottom: 30px;
        width: 100%;
        height: 50px;
        .dd_word {
          width: 100%;
          height: 50px;
          font-size: 24px;
          font-weight: 550;
          float: left;
          color: #343434;
          line-height: 50px;
        }
      }
      .clip_card {
        max-height: 746px;
        max-width: 1080px;
        overflow: hidden;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        padding: 0;
        [name="cptmarginRight"] {
          margin-right: 20px;
        }
        .cpt_type0 {
          width: 255px;
          height: 310px;
          height: 340px;
          list-style: none;
          cursor: pointer;
          position: relative;
          // margin-right: 0px;
          margin-bottom: 20px;
          box-shadow: 0 0 15px 0 rgb(0 0 0 / 10%);
          .dd_cpt_card_wrap {
            width: 100%;
            height: 100%;
            border: 1px solid #eeeeee;
            box-shadow: 0 0 15px 0 rgb(0 0 0 / 10%);
            .dd_cpt_logo {
              width: 100%;
              height: 154px;
              position: relative;
              img {
                display: block;
                width: 100%;
                height: 100%;
              }
              .status_icon_g {
                padding: 8px 6px;
                font-size: 12px;
                position: absolute;
                top: 0;
                right: 0;
                background: rgb(43, 103, 238);
                color: white;
              }
            }
            .cpt_card_wrap {
              .dd_cpt_intro1 {
                padding: 14px 0 0 16px;
                .dd_cpe_name {
                  font-size: 18px;
                  font-weight: 560;
                  color: #333;
                  margin-bottom: 10px;
                  width: 228px;
                  line-height: 20px;
                  height: 48px;
                  word-break: break-all;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 2;
                  overflow: hidden;
                }
              }
              .dd_cpt_intro2 {
                border-top: 1px solid #eeeeee;
                .dd_cpt_time {
                  font-size: 12px;
                  height: 12px;
                  color: #999;
                  margin-top: 10px;
                  margin-bottom: 16px;
                  margin-left: 16px;
                }
              }
            }
          }
          .shade_wrap {
            width: 254px;
            height: 310px;
            height: 340px;
            display: none;
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 10;
            text-align: center;
            background: rgb(#000, 0.9);
            transition: opacity 2s;
            .dd_hover_shade {
              width: 100%;
              height: 100%;
              display: block;
              p {
                color: #fff;
                font-size: 16px;
                margin-top: 114px;
              }
              button {
                display: block;
                width: 125px;
                height: 38px;
                font-size: 17px;
                background: rgb(43, 103, 238);
                color: #fff;
                text-decoration: none;
                text-align: center;
                line-height: 38px;
                border-radius: 3px;
                margin: 50px auto;
                border: none;
                cursor: pointer;
                border: 0px;
                outline: none;
              }
            }
          }
        }
        .cpt_type0:hover .shade_wrap {
          display: block;
          transition: opacity 2s;
          opacity: 1;
        }
      }
    }
    .dd_more_cpt_btn {
      text-align: center;
      line-height: 44px;
      width: 200px;
      height: 44px;
      border: none;
      margin: 0 auto;
      display: block;
      background: rgb(43, 103, 238);
      color: white;
      font-size: 18px;
      outline: none;
      cursor: pointer;
    }
  }
}
</style>